@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700;800&display=swap");
@import "../scss/varibles.scss";
/* main-section Css start from here */
.main-section {
  background: #f3f3f3;
  padding: 30px 0 20px 0;
  @include min-768 {
    padding: 60px 0 20px 0;
  }
  @include min-1920 {
    padding: 90px 0 30px 0;
  }
  .main-section-content {
    h1 {
      font-weight: 600;
      font-size: 26px;
      line-height: 34px;
      margin: 0 auto;
      text-align: center;
      padding: 0 20px;
      margin-bottom: 30px;
      @include min-768 {
        font-size: 32px;
        line-height: 46px;
        margin-bottom: 50px;
      }
      @include min-1200 {
        font-size: 44px;
        line-height: 65px;
        margin-bottom: 70px;
      }
      @include min-1440 {
        font-size: 64px;
        line-height: 90px;
        margin-bottom: 80px;
      }
      @include min-1920 {
        font-size: 72px;
        line-height: 108px;
        // max-width: 1340px;
        margin-bottom: 90px;
      }
      span {
        @include min-768 {
          display: block;
        }
      }
    }
    .btn-wrap {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      .btn-primary {
        margin-right: 24px;
      }
    }
    .book-pages-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      padding-top: 30px;
      @include min-768 {
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
      }
      @include min-1200 {
        margin-bottom: 70px;
        padding-top: 0;
      }
      p {
        color: var(--primary-color);
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        @include min-768 {
          font-size: 16px;
          text-align: left;
        }
        @include min-1200 {
          font-size: 22px;
          text-align: left;
        }
      }
    }
  }
}

/* featured-books-section CSS start here */
.featured-books-section {
  margin: 40px 0;
  @include min-768 {
    margin: 80px 0;
  }
  @include min-1024 {
    margin: 100px 0;
  }
  @include min-1200 {
    margin: 120px 0;
  }
  @include min-1440 {
    margin: 140px 0;
  }
  @include min-1600 {
    margin: 160px 0;
  }
  @include min-1920 {
    margin: 180px 0;
  }
  .featured-books-content {
    background: linear-gradient(77.83deg, #353794 8.6%, #8131d9 90.31%);
    border-radius: 12px;
    color: white;
    padding: 20px;
    @include min-768 {
      padding: 30px;
    }
    @include min-1024 {
      padding: 35px;
    }
    @include min-1440 {
      padding: 35px 60px;
    }
    @include min-1920 {
      padding: 45px 75px;
    }
    .Books-listing {
      margin-top: 20px;
      @include min-768 {
        margin-top: 40px;
      }
      @include min-1200 {
        margin-top: 60px;
      }
      .left-content {
        @include min-768 {
          width: 100%;
        }
        @include min-1600 {
          width: 35%;
        }
        .add-to-cart-book-box {
          @include min-1200 {
            width: 100%;
            margin-left: 40px;
          }
          @include min-1600 {
            margin-left: 00px;
          }
          .add-cart-book-image {
            @include min-768 {
              margin: 0;
            }
          }
          .add-cart-book-data {
            @include min-768 {
              width: calc(100% - 150px);
              padding-left: 40px;
            }
          }
        }
        .large-box {
          background: linear-gradient(
            229.06deg,
            rgba(129, 49, 217, 0.7) 47.2%,
            rgba(75, 7, 149, 0.7) 105.92%
          );
          border: 1px solid #ffffff;
          border-radius: 10px;
          padding: 18px;
          @include min-1200 {
            display: flex;
          }
          @include min-1600 {
            flex-direction: column;
            margin-right: 40px;
          }
          img.mx-auto {
            @include min-1200 {
              margin: 0 !important;
              max-height: 257px;
              object-fit: contain;
            }
          }
        }
      }
      .right-content {
        padding-left: 0px;
        @include min-1600 {
          width: 65%;
        }
        .add-to-cart-book-box {
          width: 100%;
          margin-top: 30px;
          @include min-768 {
            width: calc(50% - 20px);
            margin-top: 40px;
          }
          @include min-1200 {
            justify-content: space-between;
          }
          @include min-1600 {
            margin-bottom: 35px;
            margin-top: 0;
          }
        }
      }
    }
  }
}

/* trending-books-section css */
.trending-books-section {
  margin-bottom: 50px;
  @include min-768 {
    margin-bottom: 80px;
  }
  @include min-1024 {
    margin-bottom: 100px;
  }
  @include min-1200 {
    margin-bottom: 120px;
  }
  @include min-1440 {
    margin-bottom: 150px;
  }
  .Books-listing {
    &.vertical-align {
      @include min-768 {
        width: calc(100% + 40px);
      }
      .trending-books-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 20px;
        width: 100%;
        @include min-768 {
          margin: 0 -20px;
          margin-top: 40px;
        }
        @include min-1920 {
          margin: 0 -25px;
          margin-top: 60px;
        }
        .add-to-cart-book-box {
          padding: 0;
          flex-direction: column;
          min-width: auto;
          .add-cart-book-data {
            margin-left: 0;
            width: 100%;
          }
        }
        li {
          width: 100%;
          margin-bottom: 30px;
          @include min-768 {
            width: 50%;
            padding: 0 20px;
          }
          @include min-1024 {
            width: 33.33%;
          }
          @include min-1440 {
            width: 25%;
          }
          @include min-1920 {
            width: 20%;
            padding: 0 25px;
          }
          .add-to-cart-book-box {
            .add-cart-book-image {
              max-width: 100%;
              margin: 0;
              min-width: 100%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/*  new-trending-book-section CSS start*/
.new-trending-book-section {
  margin-bottom: 80px;
  @include min-768 {
    margin-bottom: 100px;
  }
  @include min-1024 {
    margin-bottom: 120px;
  }
  @include min-1200 {
    margin-bottom: 150px;
  }
  @include min-1440 {
    margin-bottom: 180px;
  }

  .new-trending-book-content {
    background: url("../images/new-trending-background.png") no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    background-attachment: fixed;
    border-radius: 10px;
    padding: 20px;
    @include min-768 {
      padding: 40px;
    }
    @include min-1024 {
      padding: 40px 70px 40px 40px;
    }
    @include min-1200 {
      padding: 40px 100px 40px 40px;
    }
    @include min-1440 {
      padding: 40px 160px 40px 45px;
    }
    .left-part {
      width: 100%;
      @include min-1024 {
        width: 50%;
      }
      @include min-1440 {
        width: auto;
      }
      h6 {
        color: var(--white-color);
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        max-width: 475px;
        margin-bottom: 30px;
        @include min-768 {
          font-size: 28px;
          line-height: 38px;
        }
        @include min-1024 {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
    .right-part {
      width: 100%;
      margin-top: 30px;
      @include min-1024 {
        width: 50%;
      }
      @include min-1440 {
        width: auto;
      }
      ul {
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(33.33% - 15px);
          @include min-1024 {
            width: auto;
          }
          &:nth-child(1),
          &:nth-child(3) {
            @include min-1024 {
              transform: rotate(30deg);
            }
          }
          &:nth-child(2) {
            @include min-1440 {
              padding: 0 80px;
            }
          }
          img {
            @include min-768 {
              width: 100%;
            }
            @include min-1024 {
              width: auto;
            }
          }
        }
      }
    }
  }
}

/* top-selling-books CSS start here */
.top-selling-books {
  margin-bottom: 80px;
  @include min-768 {
    margin-bottom: 100px;
  }
  @include min-1024 {
    margin-bottom: 120px;
  }
  @include min-1200 {
    margin-bottom: 150px;
  }
  @include min-1440 {
    margin-bottom: 180px;
  }
  .top-selling-books-content {
    background: var(--dark-blue);
    padding: 20px;
    border-radius: 10px;
    @include min-768 {
      padding: 40px;
    }
    @include min-1200 {
      padding: 40px 40px 0 40px;
    }
    @include min-1440 {
      padding: 70px 85px 0 85px;
    }
    .slider-content {
      width: 100%;
      background-color: #303185;
      border: 1px solid var(--white-color);
      border-radius: 16px 16px;
      padding: 45px 25px 0 25px;
      text-align: center;
      position: relative;
      margin-bottom: 30px;
      @include min-1200 {
        width: 33%;
        border-radius: 16px 16px 0 0;
        border-bottom: 0;
        margin-bottom: 0;
      }
      .add-label {
        background: url("../images/add-backgroun.png") no-repeat;
        background-size: cover;
        position: absolute;
        left: -9px;
        width: 66px;
        height: 38px;
      }
      .slider-data {
        .small-btn {
          display: inline-flex;
          p {
            color: white;
            background: rgba(158, 159, 212, 0.5);
            border-radius: 24px;
            padding: 8px 16px;
            font-size: 14px;
            @include min-768 {
              font-size: 16px;
            }
          }
        }
        .slider-title {
          color: white;
          font-weight: 700;
          font-size: 22px;
          line-height: 32px;
          margin: 10px 0;
          @include min-768 {
            font-size: 32px;
            line-height: 44px;
          }
        }
        .author-name {
          color: #9e9fd4;
        }
        .btn-wrap {
          padding: 0 40px;
          align-items: center;
          margin: 30px 0;
          flex-wrap: wrap;
          @include min-768 {
            margin: 45px 0;
            justify-content: center !important;
          }
          @include min-1200 {
            margin: 30px 0;
            padding: 0 10px;
          }
          @include min-1440 {
            margin: 45px 0;
            padding: 0 40px;
          }
          .btn {
            padding: 13px 22px;
            border: 1px solid #5c5d8f;
            border-radius: 4px;
            background-color: transparent;
            margin-bottom: 10px;
            @include min-768 {
              margin-bottom: 0;
              margin-right: 40px;
            }
            @include min-1200 {
              margin-bottom: 0;
              margin-right: 10px;
              font-size: 13px;
              padding: 8px 15px;
            }
            @include min-1600 {
              padding: 13px 22px;
              font-size: 18px;
            }
            span {
              margin-right: 7px;
              display: inline-block;
            }
          }
          a {
            color: white;
            svg {
              margin-left: 8px;
            }
            @include min-1200 {
              font-size: 14px;
            }
            @include min-1600 {
              font-size: 16px;
            }
          }
        }
      }
    }
    .right-content {
      width: 100%;
      @include min-1200 {
        width: 67%;
        padding-left: 42px;
        align-content: flex-start;
      }
      .headng {
        margin-bottom: 30px;
      }
      .add-to-cart-book-box {
        margin-bottom: 40px;
        @include min-768 {
          width: calc(50% - 20px);
          justify-content: space-between;
        }
        .add-cart-book-data {
          .title {
            h3 {
              @include min-1200 {
                margin-top: 6px;
              }
              @include min-1600 {
                margin-top: 15px;
              }
            }
          }
          .price {
            @include min-1200 {
              flex-wrap: wrap;
              margin-top: 20px;
            }
            @include min-1600 {
              margin-top: 32px;
            }
          }
          .btn-wrap {
            .btn {
              @include min-1200 {
                padding: 10px 16px;
                font-size: 13px;
              }
              @include min-1600 {
                padding: 14px 28px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
/* top-authors-section CSS start here*/
.top-authors-section {
  margin-bottom: 80px;
  @include mobile {
    overflow-x: hidden;
  }
  @include min-768 {
    margin-bottom: 100px;
  }
  @include min-1024 {
    margin-bottom: 120px;
  }
  @include min-1200 {
    margin-bottom: 150px;
  }
  @include min-1440 {
    margin-bottom: 180px;
  }
  .top-autors-content {
    .authors-list {
      margin: 30px -25px 0 -25px;
      @include mobile {
        margin: 10px -25px 0 -25px;
      }
      li {
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 4px;
        margin: 0 25px;
        margin-top: 30px;
        @include min-768 {
          width: calc(50% - 50px);
        }
        @include min-1200 {
          width: calc(33.33% - 50px);
        }
        @include min-1600 {
          width: calc(25% - 50px);
        }
        .background-box {
          height: 125px;
          background: url("../images/authors-background.png") no-repeat;
          background-size: cover;
        }
        .author-detail {
          margin-top: -65px;
          padding: 0 25px 25px 25px;
          .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            margin: 22px 0 10px 0;
          }
          .social-network {
            display: flex;
            justify-content: space-between;
            margin-top: 28px;
            align-items: center;
            li {
              border: 0;
              border-right: 1px solid var(--border-color);
              border-radius: 0;
              width: 29%;
              margin: 0;
              &:nth-child(2) {
                @include min-1920 {
                  padding-left: 18px;
                }
              }
              &:last-child {
                border: 0;
                @include min-1920 {
                  min-width: 105px;
                  margin-left: 10px;
                }
              }
              .heading {
                font-weight: 600;
                font-size: 18px;
                line-height: 37px;
                color: var(--primary-color);
              }
              span {
                font-size: 14px;
              }
            }
          }
          .btn-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 33px;
            .btn {
              @include min-1200 {
                padding: 12px 20px;
                font-size: 14px;
              }
              @include min-1920 {
                padding: 20px 32px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .headng {
      flex-wrap: wrap;
      .left-content {
        &.d-flex {
          @include mobile {
            width: 100%;
            justify-content: space-between;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

/* publish book section CSS start*/
.publish-book-section {
  margin-bottom: 80px;
  @include min-768 {
    margin-bottom: 100px;
  }
  @include min-1024 {
    margin-bottom: 120px;
  }
  @include min-1200 {
    margin-bottom: 150px;
  }
  @include min-1440 {
    margin-bottom: 180px;
  }
  .publish-book-content {
    padding: 20px;
    background: linear-gradient(180deg, #33b469 0%, #055b2a 100%);
    border-radius: 10px;
    flex-direction: column-reverse;
    padding: 20px;
    flex-wrap: wrap;
    @include min-768 {
      padding: 40px;
    }
    @include min-1024 {
      flex-direction: row;
      padding: 60px;
      flex-wrap: unset;
    }
    .left-part {
      width: 100%;
      margin-top: 20px;
      @include min-1024 {
        width: 60%;
        margin-top: 0;
      }
      h6 {
        color: var(--white-color);
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
        @include min-768 {
          font-size: 28px;
          line-height: 40px;
          text-align: center;
        }
        @include min-1024 {
          text-align: left;
        }
        @include min-1200 {
          font-size: 36px;
          line-height: 52px;
        }
        @include min-1440 {
          font-size: 42px;
          line-height: 63px;
        }
      }
      p {
        color: var(--white-color);
        font-size: 16px;
        line-height: 24px;
        @include min-768 {
          text-align: center;
        }
        @include min-1024 {
          font-size: 18px;
          line-height: 27px;
          max-width: 500px;
          text-align: left;
        }
      }
      .btn-wrap {
        margin-top: 40px;
        @include min-768 {
          text-align: center;
        }
        @include min-1024 {
          text-align: left;
        }
      }
    }
  }
}

/* client-review-section CSS start here */
.client-review-section {
  margin-bottom: 80px;
  @include min-768 {
    margin-bottom: 100px;
  }
  @include min-1024 {
    margin-bottom: 120px;
  }
  @include min-1200 {
    margin-bottom: 150px;
  }
  @include min-1440 {
    margin-bottom: 180px;
  }
  .client-reviw-content {
    .heading {
      h5 {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 15px;
        @include min-768 {
          font-size: 30px;
          line-height: 40px;
          margin-bottom: 24px;
        }
        @include min-1024 {
          font-size: 38px;
          line-height: 46px;
        }
        @include min-1200 {
          font-size: 40px;
          line-height: 52px;
        }
        @include min-1440 {
          font-size: 48px;
          line-height: 67px;
        }
      }
      span {
        text-align: center;
        display: block;
        font-size: 18px;
        line-height: 25px;
        @include mobile {
          font-size: 14px;
        }
      }
    }
    .client-review-list {
      margin: 30px -15px 0 -15px;
      @include mobile {
        margin: 10px -15px 0 -15px;
      }
      li {
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 10px;
        padding: 18px 25px;
        margin: 0 15px;
        margin-top: 30px;
        @include min-768 {
          width: calc(50% - 30px);
        }
        @include min-1200 {
          width: calc(33.33% - 30px);
        }
        .review li {
          padding: 0;
          border: 0;
          width: 100%;
          margin: 0 0 0 8px;
          svg {
            fill: #fdc12a;
            font-size: 22px;
          }
        }
        .review-comment {
          margin-top: 24px;
          h2 {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 16px;
            span {
              font-size: 14px;
              line-height: 18px;
              color: var(--light-grey);
              font-weight: 400;
            }
          }
          p {
            font-size: 18px;
            line-height: 27px;
            color: var(--light-grey);
            font-weight: 400;
          }
        }
      }
    }
  }
}

/* offer-banner-section CSS start here */
.offer-banner-section {
  margin-bottom: 80px;
  @include min-768 {
    margin-bottom: 100px;
  }
  @include min-1024 {
    margin-bottom: 120px;
  }
  @include min-1200 {
    margin-bottom: 150px;
  }
  @include min-1440 {
    margin-bottom: 180px;
  }
  .offer-bannner-content {
    .column {
      background-color: var(--primary-color);
      border-radius: 10px;
      padding: 0 30px 30px 30px;
      width: 100%;
      flex-wrap: wrap;
      @include min-768 {
        width: calc(50% - 20px);
        padding: 0 30px 30px 30px;
      }
      @include min-1200 {
        flex-wrap: unset;
      }
      @include min-1600 {
        padding: 0 30px 55px 55px;
      }
      &.flex-row-reverse {
        background-color: var(--dark-blue);
        padding: 0 30px 30px 30px;
        margin-top: 30px;
        @include min-768 {
          margin-top: 0;
          padding: 0 30px 30px 30px;
        }
        @include min-1600 {
          padding: 0 55px 55px 30px;
        }
        .offer-text {
          margin-right: 0;
          margin-left: 0;
          @include min-1200 {
            margin-right: 25px;
          }
          @include min-1600 {
            margin-right: 42px;
          }
        }
      }
      .timer-banner {
        background: url("../images/timer-angle-bc.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 264px;
        min-width: 264px;
        height: 432px;
        .header {
          border-bottom: 1px solid var(--border-color);
          padding: 11px 16px;
          h5 {
            font-size: 14px;
            color: var(--light-grey);
            p {
              color: var(--dark-blue);
              display: flex;
              font-size: 18px;
              svg {
                fill: #fdc12a;
                margin-right: 5px;
                font-size: 18px;
              }
            }
          }
        }
        .timer-content {
          margin-top: 28px;
          padding: 0 20px;
          .timer {
            display: flex;
            justify-content: center;
            p {
              background: var(--green-color);
              border-radius: 22px;
              padding: 12px 22px 9px 22px;
              font-size: 22px;
              color: var(--white-color);
              svg {
                margin-right: 5px;
                font-size: 22px;
                position: relative;
                top: -1px;
              }
            }
          }
          .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            margin: 28px 0 24px 0;
            text-align: center;
          }
          .price {
            font-size: 32px;
            color: var(--green-color);
            font-weight: 700;
            line-height: 44px;
            text-align: center;
            margin-bottom: 30px;
          }
          .btn-wrap .btn {
            width: 100%;
            border: 1px solid var(--border-color);
            background-color: var(--white-color);
            color: var(--green-color);
            padding: 16px 30px;
            svg {
              font-size: 20px;
              position: relative;
              top: -1px;
              margin-right: 5px;
            }
          }
        }
      }
      .offer-text {
        margin-left: 0px;
        padding-top: 24px;
        @include min-1200 {
          margin-left: 25px;
        }
        @include min-1600 {
          padding-top: 34px;
          margin-left: 42px;
        }
        h4,
        h3 {
          font-size: 28px;
          line-height: 36px;
          font-style: italic;
          color: white;
          @include min-1200 {
            font-size: 28px;
            line-height: 47px;
          }
          @include min-1440 {
            font-size: 30px;
          }
          @include min-1600 {
            font-size: 34px;
            line-height: 47px;
          }
        }
        .image {
          padding-right: 0;
          margin-top: 32px;
          @include min-1200 {
            padding-right: 60px;
          }
        }
      }
    }
  }
}

/* download-section CSS Start here */
.book-download-section {
  .book-download-content {
    padding: 0 20px 20px 20px;
    background: linear-gradient(77.83deg, #353794 8.6%, #8131d9 90.31%);
    border-radius: 10px;
    flex-wrap: wrap;
    @include min-768 {
      padding: 0 50px 50px 50px;
    }
    @include min-1024 {
      padding: 10px 60px 0 30px;
      flex-wrap: unset;
    }
    @include min-1200 {
      padding: 0 50px 0 35px;
    }
    @include min-1440 {
      padding: 0 60px 0 35px;
    }
    @include min-1920 {
      padding: 0 70px 0 50px;
    }
    .right-part {
      width: 100%;
      padding-left: 0px;
      margin-top: 20px;
      @include min-768 {
        text-align: center;
      }
      @include min-1024 {
        width: 80%;
        padding-left: 50px;
        margin-top: 0;
        text-align: left;
      }
      @include min-1200 {
        width: 60%;
      }
      h6 {
        color: var(--white-color);
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
        @include min-768 {
          font-size: 28px;
          line-height: 40px;
        }
        @include min-1024 {
          text-align: left;
        }
        @include min-1200 {
          font-size: 36px;
          line-height: 52px;
        }
        @include min-1440 {
          font-size: 42px;
          line-height: 63px;
        }
        @include min-1800{
          font-size: 48px;
          line-height: 72px;
        }
      }
      p {
        color: var(--white-color);
        font-size: 16px;
        line-height: 24px;
        @include min-768 {
          font-size: 18px;
          line-height: 28px;
        }
      }
      .btn-wrap {
        margin-top: 56px;
        @include mobile {
          margin-top: 34px;
        }
        @include min-1024 {
          margin-top: 30px;
        }
        .btn {
          margin-right: 30px;
          @include mobile {
            margin-right: 10px;
            font-size: 12px;
            padding: 7px 12px;
          }
          @include min-1024 {
            padding: 8px 14px;
            font-size: 12px;
          }
          @include min-1440 {
            padding: 16px 32px;
            font-size: 18px;
          }
          svg {
            margin-right: 16px;
            @include mobile {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
/* footer CSS start here */
footer {
  margin-top: 80px;
  @include min-768 {
    margin-top: 100px;
  }
  @include min-1024 {
    margin-top: 120px;
  }
  @include min-1200 {
    margin-top: 150px;
  }
  @include min-1440 {
    margin-top: 180px;
  }
  .footer-content {
    .column {
      width: 100%;
      min-width: 230px;
      margin-bottom: 40px;
      @include min-768 {
        width: calc(50% - 30px);
      }
      @include min-1200 {
        width: auto;
        min-width: 230px;
      }
      &:first-child {
        @include min-1200 {
          width: 29%;
        }
      }
      .logo-image {
        img {
          @include mobile {
            max-width: 180px;
          }
        }
      }
      .desc {
        font-size: 18px;
        line-height: 25px;
        margin: 30px 0 48px;
      }
      .payment .payment-title {
        font-size: 18px;
        line-height: 33px;
        font-weight: 500;
        margin-bottom: 10px;
        @include min-768 {
          margin-bottom: 34px;
          font-size: 24px;
        }
      }
      .column-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
        margin-bottom: 10px;
        @include min-768 {
          margin-bottom: 34px;
          font-size: 24px;
        }
      }
      .link-list li a {
        color: var(--light-grey);
        font-size: 14px;
        line-height: 25px;
        margin-top: 16px;
        display: block;
        @include min-768 {
          font-size: 18px;
          margin-top: 22px;
        }
      }
    }
  }
  .copy-right {
    border-top: 1px solid var(--border-color);
    margin-top: 0px;
    padding: 14px 0;
    @include min-1200 {
      margin-top: 50px;
    }
    .copy-right-text {
      @include mobile {
        font-size: 16px !important;
        margin-bottom: 5px;
      }
    }
    .social-icon {
      li {
        &:first-child {
          @include mobile {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

/* Header css */
header {
  @include tablet {
    overflow-x: hidden;
  }
  .header-top {
    border-bottom: 1px solid var(--border-color);
    padding: 25px 0;
    @include tablet {
      display: none;
      position: relative;
    }
  }
  .header-middle {
    padding: 30px 0;
    @include tablet {
      padding: 50px 0 10px 0;
    }
    .column {
      .logo-image {
        max-width: 170px;
        @include min-1024 {
          max-width: 180px;
          min-width: 180px;
        }
        @include min-1200 {
          min-width: 235px;
          max-width: unset;
        }
      }
      .header-search {
        min-width: 250px;
        position: relative;
        @include tablet {
          display: none;
        }
        @include min-1200 {
          min-width: 350px;
        }
        @include min-1440 {
          min-width: 560px;
        }
        input {
          padding-right: 80px;
        }
        .btn {
          position: absolute;
          top: 0;
          right: 0;
          padding: 15px 25px;
          border: 0;
          border-radius: 0 4px 4px 0;
        }
      }
      .icon {
        margin-right: 40px;
        position: relative;
        @include tablet {
          margin-right: 25px;
        }
        svg {
          font-size: 29px;
          margin: 0 auto;
          display: flex;
          @include tablet {
            font-size: 20px;
          }
        }
        span {
          display: block;
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          line-height: 19px;
          margin-top: 4px;
          @include tablet {
            display: none;
          }
        }
      }
      .btn-group {
        .dropdown-toggle-split {
          svg {
            &:last-child {
              display: none;
            }
          }
        }
      }
      @include tablet {
        .btn-group {
          .btn {
            &.btn-primary {
              display: none;
            }
          }
          .dropdown-toggle-split {
            display: block !important;
            border-radius: 0px;
            padding: 0;
            background: transparent;
            color: var(--body-color);
            font-size: 20px;
            &:hover {
              border-color: transparent;
            }
            svg {
              font-size: 20px;
              &:first-child {
                display: none;
              }
              &:last-child {
                display: block;
              }
            }
          }
        }
      }

      &:last-child {
        @include tablet {
          position: absolute;
          top: 0;
          left: 0;
          right: 0px;
          border-bottom: 1px solid var(--border-color);
          width: 100%;
          justify-content: flex-end;
          padding: 10px 20px;
        }
        @include min-768 {
          padding: 10px 40px;
        }
        @include min-1024 {
          padding: 0;
        }
      }
      &.humberger-menu {
        display: none;
        @include tablet {
          display: block;
          svg {
            font-size: 30px;
            color: var(--primary-color);
          }
        }
      }
    }
  }
  .header-bottom {
    padding: 26px 0;
    background-color: #6828ad;
    @include tablet {
      position: absolute;
      top: 0;
      bottom: 0;
      right: -100%;
      width: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: all 1s ease-in;
    }
    &.show {
      @include tablet {
        right: 0;
        width: 100%;
        opacity: 1;
        visibility: visible;
        transition: all 1s ease-in;
        z-index: 999;
      }
    }
    .navbar-item {
      @include tablet {
        flex-direction: column;
        padding-top: 40px;
      }
      .close-menu {
        display: none;
        svg {
          font-size: 16px;
          color: var(--white-color);
        }
        @include tablet {
          display: block;
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
      li {
        margin-right: 40px;
        @include tablet {
          margin: 0 0 18px 0;
        }
        &.dd-menu-wrapper {
          position: relative;
          @include min-1024 {
            &:hover {
              .dd-menu-content {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: all;
                transition: all 0.4s ease-in;
              }
            }
          }
          .dd-menu {
            background: #ffffff;
            box-shadow: 0px 6px 23px #00000033;
            border-radius: 4px;
            width: 75vw;
            padding: 20px 30px;
            margin: 25px;
            overflow: auto;
            @include tablet {
              width: auto;
              padding: 15px;
              margin: 10px 0 0 0;
              flex-direction: column;
            }
            @include min-1024 {
              max-height: 750px;
            }
            .column {
              .column-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 16px;
              }
              .link-list {
                li {
                  a {
                    color: var(--light-grey);
                    line-height: 26px;
                    margin-bottom: 16px;
                    display: block;
                    @include tablet {
                      width: 100%;
                      margin: 0;
                    }
                  }
                  &:last-child {
                    a {
                      margin-bottom: 0;
                      font-weight: 500;
                      color: var(--body-color);
                      padding-top: 5px;
                      margin-bottom: 20px;
                    }
                  }
                }
              }
            }
            .left-part {
              margin: 0 -15px;
              @include tablet {
                width: 100%;
                margin: 0;
              }
              .column {
                width: 50%;
                padding: 0 15px;
                margin: 20px 0 0 0;
                @include tablet {
                  width: 100%;
                  margin: 0 0 15px 0;
                  padding: 0;
                  border-bottom: 1px solid var(--border-color);
                }
                @include min-1200 {
                  width: 33.33%;
                }
                @include min-1440 {
                  width: 25%;
                }
                li {
                  @include tablet {
                    margin: 0 0 10px 0;
                  }
                }
              }
            }
            .right-part {
              min-width: 230px;
              border-left: 1px solid var(--border-color);
              padding-left: 40px;
              padding-top: 20px;
              @include tablet {
                border-left: 0;
                padding: 0;
              }
              @include min-1200 {
                min-width: 290px;
              }
              .column {
                .link-list {
                  li {
                    @include tablet {
                      margin: 0 0 10px 0;
                    }
                    &:last-child {
                      a {
                        color: var(--light-grey);
                        padding: 0;
                        font-weight: unset;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
          .dd-menu-content {
            position: absolute;
            top: 12px;
            left: -100px;
            overflow: auto;
            opacity: 0;
            padding-top: 20px;
            visibility: hidden;
            transform: translateY(20px);
            transition: all 0.4s ease-in;
            pointer-events: none;
            z-index: 999;
            @include tablet {
              position: absolute;
              top: 0;
              left: 0;
              padding: 0;
              display: none;
            }
            &.show-dropdown {
              @include tablet {
                display: block;
                visibility: visible;
                opacity: 1;
                pointer-events: all;
              }
              .dd-menu {
                @include tablet {
                  max-height: calc(100vh - 200px);
                }
              }
            }
          }
          .dropdown-arrow {
            @include tablet {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
.book-category-section {
  @include mobile {
    position: relative;
  }
  .book-category-content {
    .filter-section {
      width: 250px;
      border-right: 1px solid var(--border-color);
      @include min-1200 {
        width: 320px;
      }
      &.show {
        @include mobile {
          width: 320px;
          height: auto;
        }
        .filter-header {
          .filter-header-wrapper {
            @include mobile {
              opacity: 1;
              visibility: visible;
              pointer-events: all;
            }
          }
        }
        .category-page-accordian {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
      }
      @include mobile {
        position: absolute;
        left: 0;
        background: white;
        border: 0;
        z-index: 9;
        box-shadow: 0px 6px 15px #00000033;
        min-height: auto;
        border-radius: 4px;
        padding: 10px 20px;
        width: 70px;
        height: 100%;
      }
      .filter-header {
        padding-right: 24px;
        margin-bottom: 38px;
        @include mobile {
          padding-right: 0;
          margin-bottom: 30px;
        }
        .filter-header-wrapper {
          @include mobile {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
          }
        }
        h1 {
          font-weight: 700;
          font-size: 32px;
          line-height: 45px;
          @include mobile {
            font-size: 26px;
          }
        }
        a {
          color: #ed3a3a;
        }
        .filter-icon {
          display: block;
          font-size: 32px;
          margin-bottom: 10px;
          @include min-768 {
            display: none;
          }
        }
      }
      .category-page-accordian {
        padding-right: 30px;
        @include mobile {
          padding-right: 0;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
        @include min-1200 {
          padding-right: 56px;
        }
        .accordion-item {
          margin-bottom: 25px;
          .input-group {
            margin-bottom: 5px;
            .input-box {
              padding: 7px 10px;
              @include mobile {
                margin-bottom: 7px;
              }
            }
          }
        }
      }
    }
    .books-listing {
      width: calc(100% - 250px);
      padding-left: 40px;
      @include min-1200 {
        width: calc(100% - 320px);
        padding-left: 56px;
      }
      @include mobile {
        width: 100%;
        padding-left: 0;
        margin-left: 70px;
      }
      .books-listing-header {
        h2 {
          font-weight: 700;
          font-size: 32px;
          line-height: 45px;
          margin-bottom: 14px;
        }
        .filter-label-list {
          margin-top: 25px;
          li {
            margin-right: 22px;
            margin-top: 7px;
          }
        }
      }
      .trending-books-section {
        margin-bottom: 0;
        .trending-books-list {
          margin-top: 32px;
          li {
            @include min-1600 {
              width: 25%;
            }
          }
        }
      }
    }
  }
}

/* author-list-section CSS */
.author-list-section {
  .author-list-header {
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 14px;
      @include mobile {
        font-size: 26px;
      }
    }
  }
  .author-list-tabbing {
    margin: 50px 0;
    position: relative;
    @include mobile {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    .author-list-tab-left {
      width: 100%;
      .nav-tabs {
        background: #f3f3f3;
        padding: 8px 10px;
        margin-right: 200px;
        border: 0;
        flex-wrap: nowrap;
        overflow: auto;
        @include mobile {
          margin-right: 0;
        }
        .nav-item {
          .nav-link {
            background-color: transparent;
            font-size: 16px;
            line-height: 24px;
            color: var(--body-color);
            padding: 6px 26px;
            margin-right: 8px;
            border-radius: 4px;
            white-space: nowrap;
            &.active {
              background-color: var(--primary-color);
              border: 0;
              color: var(--white-color);
            }
          }
        }
      }
      .tab-content-data {
        margin-top: 50px;
      }
      .top-authors-section {
        margin-bottom: 0;
        .top-autors-content {
          .authors-list {
            margin: 0 -25px 0 -25px;
            li {
              margin-top: 0;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    .author-list-tab-right {
      position: absolute;
      right: 0;
      @include mobile {
        position: unset;
        right: unset;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
      }
      .dropdown {
        .btn {
          @include mobile {
            padding: 10px 21px;
          }
        }
      }
    }
  }
}

.book-detail-section {
  .book-detail-content {
    flex-wrap: wrap;
    .book-detail-left {
      @include mobile {
        width: 100%;
      }
      img {
        max-width: 240px;
      }
      .btn-wrap {
        margin-top: 12px;
        .btn {
          width: 100%;
        }
      }
    }
    .book-detail-right {
      width: 100%;
      @include mobile {
        margin-top: 40px;
      }
      @include min-768 {
        width: calc(100% - 240px);
        padding-left: 30px;
      }
      @include min-1200 {
        padding-left: 85px;
      }
      .book-detail-header {
        position: relative;
        .icon {
          position: absolute;
          right: 0;
          @include min-1024 {
            top: -20px;
          }
          @include min-1600 {
            top: 48px;
          }
          .icon-border {
            width: 40px;
            height: 34px;
            border-radius: 8px;
            border: 1px solid var(--border-color);
            display: flex;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
            @include min-1200 {
              width: 70px;
              height: 57px;
            }
            svg {
              font-size: 16px;
              @include min-1200 {
                font-size: 29px;
              }
            }
          }
        }
      }
      .book-details-info {
        max-width: 900px;
        h1 {
          font-weight: 500;
          font-size: 26px;
          line-height: 32px;
          margin-bottom: 18px;
          margin-top: 10px;
          @include min-768 {
            font-size: 42px;
            line-height: 52px;
            margin-top: 0;
          }
          @include min-1200 {
            font-size: 48px;
            line-height: 60px;
          }
          @include min-1440 {
            font-size: 56px;
            line-height: 78px;
          }
        }
        ul {
          li {
            color: #2f80ed;
            list-style-type: disc;
            padding-left: 3px;
            margin-left: 30px;
            @include mobile {
              list-style-type: disc;
              width: 100%;
              margin-bottom: 5px;
              margin-left: 18px;
            }
            @include min-768 {
              &:first-child {
                list-style-type: none;
                padding-left: 0;
                margin-left: 0;
              }
            }
          }
        }
        .column {
          margin: 40px 0 20px;
          display: flex;
          @include mobile {
            flex-wrap: wrap;
            margin-top: 0;
            margin-bottom: 30px;
          }
          @include min-1024 {
            margin: 40px 0 50px;
          }
          .icon {
            text-align: center;
            border-left: 1px solid var(--border-color);
            padding: 0 35px;
            @include mobile {
              text-align: left;
              border-left: 0;
              padding: 0;
              width: 100%;
              margin-top: 30px;
            }
            p {
              font-size: 14px;
              line-height: 29px;
              margin-top: 8px;
            }
            &.star-icon {
              border-left: 0;
              padding-left: 0;
              svg {
                fill: #fdc12a;
                font-size: 23px;
              }
              span {
                margin-left: 10px;
                position: relative;
                top: 3px;
                font-size: 18px;
              }
            }
          }
        }
        .btn-wrap {
          .btn-group {
            flex-wrap: nowrap;
            width: 100%;
            margin: 10px 0 0 0 !important;
            @include min-1024 {
              width: auto;
              margin: 0 24px 0 0 !important;
            }
            .dropdown-toggle-split {
              max-width: 70px;
              @include min-1024 {
                max-width: unset;
              }
            }
          }
          .dropdown {
            .dropdown-menu {
              min-width: 260px;
              .dropdown-item {
                .form-check {
                  display: flex;
                  flex-direction: row-reverse;
                  width: 100%;
                  justify-content: space-between;
                  padding-left: 0;
                  align-items: center;
                  label {
                    margin-left: 0;
                    top: 1px;
                    svg {
                      position: relative;
                      top: -1px;
                      margin-right: 6px;
                      font-size: 16px;
                    }
                    span {
                      color: var(--primary-color);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .about-book-details {
    margin-top: 50px;
    flex-wrap: wrap;
    @include min-768 {
      margin-top: 70px;
    }
    @include min-1024 {
      flex-wrap: nowrap;
    }
    .left-part {
      width: 100%;
      @include min-1024 {
        width: 50%;
        padding-right: 30px;
        margin-right: 30px;
        border-right: 1px solid #cfcfcf;
      }
      @include min-1200 {
        width: 55%;
        padding-right: 50px;
        margin-right: 50px;
      }
      .header {
        h4 {
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          @include mobile {
            font-size: 18px;
          }
        }
        a {
          color: #2f80ed;
          font-size: 18px;
          svg {
            margin-left: 4px;
          }
          span {
            @include mobile {
              display: none;
            }
          }
        }
      }
      .desc {
        font-size: 18px;
        line-height: 27px;
        color: #666666;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        margin: 24px 0 34px;
      }
      .book-tag {
        flex-wrap: wrap;
        li {
          color: #666666;
          border: 1px solid var(--border-color);
          padding: 8px 30px;
          border-radius: 36px;
          margin-right: 16px;
          margin-bottom: 8px;
        }
      }
      .review-rating {
        margin-top: 40px;
        @include min-768 {
          margin-top: 70px;
        }
        .header {
          margin-bottom: 40px;
          @include mobile {
            margin-bottom: 10px;
          }
        }
        .left-part {
          width: auto;
          padding-right: 0;
          margin-right: 0;
          border: 0;
          @include mobile {
            width: 100%;
          }
          h5 {
            font-weight: 400;
            font-size: 72px;
            line-height: 90px;
            @include mobile {
              font-size: 56px;
              line-height: 70px;
            }
          }
          .rating-list {
            margin: 8px 0 16px;
            li {
              margin-right: 5px;
              svg {
                color: #fdc12a;
                font-size: 16px;
              }
            }
          }
          span {
            font-size: 18px;
            line-height: 25px;
            color: #666666;
            display: block;
          }
        }
        .right-part {
          padding-left: 62px;
          padding-top: 15px;
          width: calc(100% - 105px);
          border: 0;
          @include mobile {
            width: 100%;
            padding-left: 0;
            margin-top: 15px;
          }
          .progress-detail {
            display: flex;
            align-items: flex-start;
            margin-bottom: 14px;
            p {
              padding-right: 16px;
              position: relative;
              top: -2px;
            }
            .progress {
              width: calc(100% - 64px);
            }
          }
        }
      }
      .rate-book-details {
        margin-top: 70px;
        @include mobile {
          margin-top: 50px;
        }
        h6 {
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
        }
        .rating-list {
          margin: 28px 0;
          li {
            margin-right: 14px;
            svg {
              font-size: 37px;
              color: #cccccc;
            }
          }
        }
        .checkbox-group {
          @include mobile {
            flex-wrap: wrap;
          }
          .form-check {
            width: 50%;
            margin: 0 0 8px 0 !important;
            @include min-1200 {
              width: auto;
              margin: 0 24px 0 0 !important;
            }
          }
        }
        .submit-input {
          margin-top: 28px;
          @include min-768 {
            flex-wrap: nowrap !important;
          }
          .btn {
            background: #e3e3e3;
            border-radius: 4px;
            min-width: 240px;
            margin-left: 20px;
            color: #666666;
            &:hover {
              border-color: transparent;
            }
            @include mobile {
              width: 100%;
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }
      .customer-review {
        margin-top: 70px;
        @include mobile {
          margin-top: 50px;
        }
        .customer-list {
          margin-top: 46px;
          @include mobile {
            margin-top: 20px;
          }
          li.customer-list-item {
            border-bottom: 1px solid #e3e3e3;
            padding-bottom: 18px;
            margin-bottom: 24px;
            .user-profile {
              min-width: 64px;
            }
            .customer-data {
              width: calc(100% - 64px);
              padding-left: 28px;
              position: relative;
              @include mobile {
                width: 100%;
                padding-left: 0;
                margin-top: 15px;
              }
              h3 {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                span {
                  font-weight: 400;
                  font-size: 15px;
                  color: var(--light-grey);
                  margin-left: 10px;
                }
              }
              .customer-rating {
                margin: 14px 0 18px;
                ul {
                  li {
                    margin-right: 6px;
                    svg {
                      color: #fdc12a;
                      font-size: 16px;
                    }
                  }
                }
                .rating-label {
                  background: rgba(129, 49, 217, 0.15);
                  border-radius: 59px;
                  color: #8131d9;
                  font-size: 14px;
                  line-height: 21px;
                  padding: 2px 14px;
                  margin-left: 12px;
                }
              }
              .customer-desc {
                font-size: 18px;
                line-height: 27px;
                color: #666666;
                a {
                  color: #2f80ed;
                }
              }
              ul {
                &.like {
                  margin-top: 16px;
                  li {
                    margin-right: 24px;
                    span {
                      margin-left: 8px;
                      color: #666666;
                    }
                  }
                }
              }
              .without-background {
                &.dropdown {
                  position: absolute;
                  top: 0;
                  right: 10px;
                }
              }
            }
          }
        }
        .without-background {
          .dropdown-item {
            font-weight: 500;
            font-size: 15px;
            line-height: 26px;
            color: var(--body-color);
          }
        }
        .btn-wrap {
          .btn {
            width: 100%;
          }
        }
      }
    }
    .right-part {
      width: 100%;
      border: 1px solid #eeeeee;
      border-radius: 8px;
      margin-top: 30px;
      @include min-1024 {
        width: 50%;
        margin-top: 0;
      }
      @include min-1200 {
        width: 45%;
      }
      .book-detail-page-tabbing {
        .nav-tabs {
          padding-top: 16px;
          .nav-item {
            width: 50%;
            .nav-link {
              text-align: center;
              width: 100%;
            }
          }
        }
        .tab-content {
          max-height: 980px;
          min-height: 980px;
          overflow: auto;
          overflow-x: hidden;
        }
        .trending-books-section {
          margin-bottom: 0;
          padding: 20px;
          @include min-1200 {
            padding: 40px;
          }
          .trending-books-content {
            .Books-listing.vertical-align {
              @include min-768 {
                width: calc(100% + 30px);
              }
            }
            .trending-books-list {
              margin: 0 -15px;
              li {
                width: 100%;
                padding: 0 15px;
                @include min-768 {
                  width: 50%;
                }
                @include min-1800 {
                  width: 33.33%;
                }
                .add-to-cart-book-box {
                  .add-cart-book-image {
                    width: 100%;
                    max-width: initial;
                  }
                }
                .add-cart-book-data {
                  .title {
                    h3 {
                      font-size: 16px;
                      line-height: 19px;
                      margin: 12px 0 6px;
                      @include min-1024 {
                        font-size: 14px;
                      }
                    }
                    p {
                      font-size: 12px;
                      line-height: 16px;
                    }
                  }
                  .price {
                    margin-top: 15px;
                    .price-number {
                      font-size: 18px;
                      span {
                        font-size: 14px;
                      }
                    }
                    .rating {
                      font-size: 16px;
                      svg {
                        font-size: 18px;
                      }
                    }
                  }
                  .btn-wrap {
                    margin-top: 20px;
                    .btn {
                      padding: 9px 22px;
                      font-size: 14px;
                      svg {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* author-detail-page-secton CSS */
.author-detail-page-secton {
  .author-details-page-content {
    .author-background {
      height: 170px;
      background: url("../images/author-detail-bg.jpg") no-repeat;
      background-size: cover;
      border-radius: 8px;
      margin-bottom: 45px;
      @include min-768 {
        height: 211px;
      }
    }
    .author-details-info {
      flex-wrap: wrap;
      .left-part {
        width: 100%;
        padding: 32px;
        border: 1px solid #eeeeee;
        border-radius: 8px;
        @include min-1024 {
          width: 370px;
        }
        .top-autors-content {
          .author-detail {
            .author-profile-image {
              margin-top: -160px;
            }
            .title {
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              margin: 26px 0 12px 0;
            }
            .social-network {
              display: flex;
              justify-content: space-between;
              margin-top: 28px;
              align-items: center;
              li {
                border: 0;
                border-right: 1px solid var(--border-color);
                border-radius: 0;
                padding-right: 20px;
                @include min-768 {
                  width: 26%;
                }
                @include min-1024 {
                  width: auto;
                }
                &:last-child {
                  border: 0;
                }
                .heading {
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 37px;
                  color: var(--primary-color);
                }
                span {
                  font-size: 14px;
                }
              }
            }
            .btn-wrap {
              margin: 30px 0 50px 0;
              @include min-1024 {
                margin: 40px 0 100px 0;
              }
              .btn {
                width: 100%;
                padding: 18px 32px;
              }
            }
            .small-title {
              font-weight: 500;
              font-size: 18px;
              line-height: 25px;
              margin-bottom: 15px;
            }
            .about-author {
              border-bottom: 1px solid var(--border-color);
              padding-bottom: 32px;
              margin-bottom: 32px;
              .author-desc {
                line-height: 24px;
              }
            }
            .author-stats {
              margin-bottom: 32px;
            }
          }
        }
      }
      .right-part {
        width: 100%;
        margin-top: 40px;
        @include min-1024 {
          width: calc(100% - 370px);
          padding-left: 50px;
          margin-top: 0;
        }
        .author-detail-page-tabbing {
          border: 1px solid #eeeeee;
          border-radius: 8px;
          .nav-tabs {
            padding-top: 16px;
            .nav-item {
              width: 50%;
              @include min-768 {
                width: 33.33%;
              }
              .nav-link {
                text-align: center;
                width: 100%;
              }
            }
          }
          .tab-content {
            .search-bar {
              margin: 48px 0 32px 0;
              padding: 0 20px;
              flex-wrap: wrap;
              @include min-768 {
                padding: 0 40px;
              }
              @include min-1024 {
                padding: 0 30px;
              }
              @include min-1200 {
                padding: 0 80px;
              }
              .header-search {
                position: relative;
                width: 100%;
                @include min-768 {
                  min-width: 450px;
                  width: auto;
                }
                @include min-1024 {
                  min-width: 330px;
                }
                @include min-1200 {
                  min-width: 290px;
                }
                @include min-1340 {
                  min-width: 330px;
                }
                @include min-1800 {
                  min-width: 450px;
                }
                input {
                  padding-right: 80px;
                }
                .btn {
                  position: absolute;
                  top: 0;
                  right: 0;
                  padding: 15px 25px;
                  border: 0;
                  border-radius: 0 4px 4px 0;
                }
              }
              .dropdown {
                @include mobile {
                  display: flex;
                  justify-content: flex-end;
                  width: 100%;
                  margin-top: 16px;
                }
              }
            }
            .trending-books-section {
              margin-bottom: 0;
              .trending-books-content {
                @include min-1024 {
                  max-height: 930px;
                  overflow: auto;
                  margin-right: 10px;
                }
                @include min-1200 {
                  margin-right: 30px;
                }
                .Books-listing {
                  padding: 0 20px;
                  @include min-768 {
                    padding: 0 40px;
                  }
                  @include min-1024 {
                    padding: 0 20px 0 30px;
                  }
                  @include min-1200 {
                    padding: 0 50px 0 80px;
                  }
                }
                .trending-books-list {
                  margin: 0 -35px;
                  @include min-1024 {
                    margin: 0 -15px;
                  }
                  @include min-1200 {
                    margin: 0 -35px;
                  }
                  li {
                    width: 100%;
                    padding: 0 35px;
                    @include min-768 {
                      width: 50%;
                    }
                    @include min-1024 {
                      padding: 0 15px;
                    }
                    @include min-1200 {
                      padding: 0 35px;
                    }
                    @include min-1600 {
                      width: 33.33%;
                    }
                    @include min-1920 {
                      width: 25%;
                    }
                    .add-to-cart-book-box {
                      .add-cart-book-image {
                        width: 100%;
                        max-width: initial;
                      }
                    }
                    .add-cart-book-data {
                      .title {
                        h3 {
                          font-size: 16px;
                          line-height: 19px;
                          margin: 12px 0 6px;
                          @include min-1024 {
                            font-size: 14px;
                          }
                        }
                        p {
                          font-size: 12px;
                          line-height: 16px;
                        }
                      }
                      .price {
                        margin-top: 15px;
                        .price-number {
                          font-size: 18px;
                          span {
                            font-size: 14px;
                          }
                        }
                        .rating {
                          font-size: 16px;
                          svg {
                            font-size: 18px;
                          }
                        }
                      }
                      .btn-wrap {
                        margin-top: 20px;
                        .btn {
                          padding: 9px 22px;
                          font-size: 14px;
                          svg {
                            font-size: 16px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .top-authors-section {
    margin-top: 40px;
    margin-bottom: 0;
    @include min-768 {
      margin-top: 86px;
    }
  }
}

/* Login Page CSS */
.login-page-section {
  .login-page-content-wrapper {
    flex-wrap: wrap;
    @include min-1024 {
      flex-wrap: nowrap;
    }
    .left-part {
      width: 100%;
      padding: 40px 20px;
      @include min-768 {
        padding: 40px;
      }
      @include min-1024 {
        width: 50%;
      }
      .logo-image {
        @include min-1024 {
          max-width: 500px;
          margin: 0 auto;
        }
        img {
          @include mobile {
            max-width: 170px;
          }
        }
      }
      .login-page-content {
        margin-top: 40px;
        @include min-1024 {
          max-width: 500px;
          margin: 40px auto 0 auto;
        }
        @include min-1200 {
          margin-top: 80px;
        }
        @include min-1600 {
          margin-top: 130px;
        }
        h2 {
          font-weight: 700;
          font-size: 26px;
          line-height: 36px;
          margin-bottom: 20px;
          @include min-768 {
            margin-bottom: 40px;
            font-size: 36px;
            line-height: 54px;
          }
          @include min-1200 {
            margin-bottom: 52px;
          }
        }
        .sign-in-option {
          @include mobile {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .btn-wrap {
            margin: 0;
            @include mobile {
              margin: 10px 0 0 0 !important;
              width: calc(50% - 5px);
            }
            a {
              color: #4d4d4d;
              border: 1px solid #cfcfcf;
              border-radius: 4px;
              padding: 14px 31px;
              display: flex;
              align-items: center;
              white-space: nowrap;
              justify-content: center;
              @include mobile {
                padding: 10px 15px;
                font-size: 12px;
              }
              @media screen and (min-width: 1024px) and (max-width: 1199px) {
                font-size: 14px;
                padding: 12px 25px;
              }
              svg {
                font-size: 22px;
                margin-right: 12px;
                @include mobile {
                  font-size: 18px;
                  margin-right: 8px;
                  min-width: 18px;
                }
                @media screen and (min-width: 1024px) and (max-width: 1199px) {
                  font-size: 20px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        .separator {
          display: flex;
          align-items: center;
          padding: 32px 0;
          &::before {
            content: " ";
            width: 50%;
            border-bottom: 1px solid #bebebe;
          }
          &::after {
            content: " ";
            width: 50%;
            border-bottom: 1px solid #bebebe;
          }
          span {
            color: #bebebe;
            font-weight: 500;
            padding: 0 16px;
          }
        }
        .input-field-group {
          .input-box {
            border: 1px solid #c5c7d0;
            margin-bottom: 24px;
          }
        }
        .checkbox-group {
          .form-check-label {
            @include mobile {
              font-size: 14px;
            }
            a {
              color: #2f80ed;
            }
          }
        }
        .btn-wrap {
          width: 100%;
          margin: 24px 0;
          .btn {
            padding: 16px 32px;
            width: 100%;
          }
        }
        .forgot-password {
          color: var(--light-grey);
          font-weight: 400;
          a {
            color: #2f80ed;
          }
        }
      }
    }
    .right-part {
      width: 100%;
      background-color: var(--primary-color);
      padding: 40px 20px;
      &.success {
        background-color: #33b469;
      }
      @include min-768 {
        padding: 40px;
      }
      @include min-1024 {
        width: 50%;
      }
      .right-part-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        @include min-1024 {
          max-width: 600px;
          margin: 0 auto;
        }
        .btn-wrap {
          display: flex;
          margin-bottom: 20px;
          @include min-1024 {
            justify-content: flex-end;
          }
          .btn {
            background-color: transparent;
            color: white;
            border-color: #ffffff80;
            padding: 15px 26px;
            font-size: 16px;
            @include mobile {
              padding: 12px 22px;
              font-size: 14px;
            }
          }
        }
        .login-page-desc {
          margin-bottom: 40px;
          @include min-1024 {
            margin-bottom: 80px;
          }
          @include min-1440 {
            margin-bottom: 100px;
          }
          h1 {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: white;
            text-shadow: 0px 4px 4px rgba(79, 30, 131, 0.5);
            @include min-768 {
              font-size: 34px;
              line-height: 50px;
            }
            @include min-1024 {
              font-size: 38px;
              line-height: 52px;
            }
            @include min-1200 {
              font-size: 44px;
              line-height: 58px;
            }
            @include min-1440 {
              font-size: 52px;
              line-height: 66px;
            }
            @include min-1800 {
              font-size: 62px;
              line-height: 80px;
            }
            span {
              display: block;
              text-transform: uppercase;
            }
          }
          .desc {
            font-size: 16px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.5);
            margin: 20px 0 25px;
            @include min-1024 {
              margin: 44px 0;
            }
          }
          .active-user {
            @include mobile {
              flex-direction: column;
              align-items: flex-start !important;
            }
            span {
              margin-left: 4px;
              color: rgba(255, 255, 255, 0.5);
              @include mobile {
                margin-top: 5px;
              }
            }
          }
        }
        .list {
          li {
            margin-right: 40px;
            @include mobile {
              margin-right: 0px;
              width: 100%;
              margin-bottom: 8px;
            }
            @media screen and (min-width: 1024px) and (max-width: 1199px) {
              width: 50%;
              margin-bottom: 8px;
              margin-right: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              color: rgba(255, 255, 255, 0.5);
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  &.register-page-section {
    .login-page-content-wrapper {
      .left-part {
        .logo-image {
          @include min-1024 {
            max-width: 690px;
            margin: 0 auto;
          }
        }
        .login-page-content {
          margin-top: 40px;
          @include min-1024 {
            max-width: 690px;
            margin-top: 60px;
          }
          @include min-1200 {
            margin-top: 80px;
          }
          h2 {
            margin-bottom: 11px;
          }
          .small-desc {
            margin-bottom: 30px;
            @include min-1024 {
              margin-bottom: 48px;
            }
          }
          .input-field-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .input-box {
              width: 100%;
              @include min-768 {
                width: calc(50% - 10px);
              }
            }
            .without-background {
              display: flex;
              justify-content: space-between;
              .btn {
                color: var(--light-grey);
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                text-align: left;
                svg {
                  position: absolute;
                  right: 20px;
                }
              }
            }
          }
        }
      }
      .right-part {
        .right-part-content {
          .btn-wrap {
            @include mobile {
              flex-wrap: wrap;
            }
            .btn {
              @include mobile {
                padding: 8px 12px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  &.forgot-password-page {
    .login-page-content-wrapper {
      min-height: 100vh;
      .left-part {
        .login-page-content {
          @include min-1024 {
            margin-top: 130px;
          }
          h2 {
            margin-bottom: 11px;
          }
          .small-desc {
            margin-bottom: 30px;
            @include mobile {
              font-size: 16px;
            }
            span {
              font-weight: 500;
            }
            a {
              font-weight: 500;
              margin-left: 6px;
              text-decoration: underline;
              color: #2f80ed;
            }
            @include min-1024 {
              margin-bottom: 48px;
            }
          }
          .input-field-group {
            .input-box {
              &:last-child {
                margin-bottom: 0;
              }
            }
            &.otp-verification {
              display: flex;
              justify-content: space-between;
              .input-box {
                margin: 0;
                max-width: 60px;
                min-height: 60px;
                min-width: 60px;
                font-size: 22px;
                font-weight: 600;
                text-align: center;
                @include mobile {
                  max-width: 45px;
                  min-height: 40px;
                  min-width: 45px;
                }
              }
            }
            &.set-new-password {
              .input-field {
                margin-bottom: 32px;
                .input-box {
                  margin: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          .otp-message {
            font-size: 18px;
            line-height: 27px;
            color: var(--light-grey);
            text-align: right;
            margin-top: 24px;
            @include mobile {
              font-size: 16px;
              margin-top: 16px;
            }
            span {
              margin-left: 8px;
              color: var(--body-color);
            }
          }
          .btn-wrap {
            margin: 32px 0 0 0;
            a {
              margin-top: 24px;
              display: block;
              text-align: center;
              color: var(--light-grey);
              svg {
                font-size: 20px;
                margin-right: 10px;
                color: var(--body-color);
              }
            }
          }
        }
      }
      .right-part {
        .right-part-content {
          .btn-wrap {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            display: none !important;
            @include min-1024 {
              display: unset !important;
            }
          }
        }
      }
    }
  }
}

/* reader-onboard-screen CSS */
.reader-onboard-screen {
  background-color: var(--primary-color);
  .reader-onboard-screen-content {
    padding: 30px 0;
    @include min-768 {
      padding: 40px 0;
    }
    @include min-1024 {
      padding: 60px 0;
    }
    @include min-1200 {
      padding: 80px 0;
    }
    .select-categories {
      background: #ffffff;
      border-radius: 12px;
      padding: 20px;
      margin: 40px 0;
      @include min-768 {
        margin: 60px 0 40px;
        padding: 35px;
      }
      @include min-1024 {
        margin: 75px 0 50px;
      }
      @include min-1200 {
        padding: 48px;
      }
      @include min-1600 {
        margin: 95px 0 65px;
      }
      .select-categories-header {
        margin-bottom: 8px;
        .header-title {
          h1 {
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 8px;
            @include min-768 {
              font-size: 30px;
              line-height: 42px;
            }
            @include min-1024 {
              font-size: 32px;
              line-height: 46px;
            }
            @include min-1200 {
              font-size: 36px;
              line-height: 54px;
            }
          }
          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: var(--light-grey);
            span {
              color: #2f80ed;
            }
            @include mobile {
              font-size: 16px;
            }
          }
        }
        .btn-wrap {
          .btn {
            background-color: #e3e3e3;
            color: #666666;
            &.selected {
              background-color: var(--primary-color);
              color: white;
            }
            @include mobile {
              min-width: auto;
              margin-top: 10px;
            }
            @include min-1024 {
              min-width: 300px;
            }
          }
        }
      }
      .categories-list {
        margin: 0 -20px;
        @include min-1600 {
          margin: 0 -40px;
        }
        li {
          width: 100%;
          margin: 0 20px;
          margin-top: 34px;
          padding: 22px 10px;
          background: #ffffff;
          border: 0.2px solid #8131d9;
          border-radius: 18px;
          text-align: center;
          cursor: pointer;
          &.selected {
            border-width: 1px;
          }
          @include min-768 {
            width: calc(33% - 40px);
            padding: 32px 10px;
          }
          @include min-1024 {
            width: calc(25% - 40px);
          }
          @include min-1600 {
            width: calc(20% - 80px);
            margin: 0 40px;
            margin-top: 34px;
          }
          img {
            margin: 0 auto;
          }
          h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            margin-top: 10px;
          }
        }
      }
    }
    .link-list {
      justify-content: flex-end;
      li {
        margin-right: 40px;
        width: auto;
        @include mobile {
          margin-right: 0px;
          width: 100%;
          margin-bottom: 8px;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
// Register as Author slider css
.register-as-author {
  .login-page-content-wrapper {
    .right-part {
      background-color: var(--dark-blue);
      .right-part-content {
        @include min-1024 {
          max-width: 540px;
        }
        .slider-section {
          padding-bottom: 100px;
          @include min-1024 {
            padding-bottom: 0;
            margin-bottom: 100px;
          }
          .slider-data {
            img {
              margin: 0 auto;
            }
            .slider-content {
              margin-top: 20px;
              @include min-1024 {
                margin-top: 50px;
              }
              h3 {
                font-weight: 500;
                font-size: 22px;
                line-height: 27px;
                margin-bottom: 16px;
                text-align: center;
                color: var(--white-color);
              }
              p {
                color: rgba(255, 255, 255, 0.5);
                line-height: 27px;
                text-align: center;
              }
            }
          }
          .carousel-indicators {
            display: block;
            margin: 0;
            bottom: -60px;
            text-align: center;
            button {
              padding: 0;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              border: 0;
            }
          }
          .carousel-control-prev,
          .carousel-control-next {
            display: none;
          }
          // .carousel-control-prev,
          // .carousel-control-next{
          //   bottom: -60px;
          //   top: unset;
          //   width: 30px;
          // }
          // .carousel-control-prev{
          //   left: calc(50% - 70px);
          // }
          // .carousel-control-next{
          //   right: calc(50% - 70px);
          // }
          // .carousel-control-prev-icon{
          //   background: url("../images/prev-arrow-angle.png") no-repeat;
          //   background-size: cover;
          //   width: 9px;
          //   height: 16px;
          // }
          // .carousel-control-next-icon{
          //   background: url("../images/next-arrow-angle.png") no-repeat;
          //   background-size: cover;
          //   width: 9px;
          //   height: 16px;
          // }
        }
        .list {
          @include min-1440 {
            justify-content: space-between;
          }
          li {
            @include min-1440 {
              margin-right: 00px;
            }
          }
        }
      }
    }
  }
}

.affiliate-account-section {
  position: relative;
  .container {
    @include min-1200 {
      padding: 0 60px;
    }
    @include min-1600 {
      padding: 0 40px;
    }
    @include min-1920 {
      padding: 0 20px;
    }
  }
  .left-box {
    width: 46px;
    height: 410px;
    position: absolute;
    top: 0;
    left: -20px;
    bottom: 0;
    background-color: var(--dark-blue);
    z-index: -1;
    @include min-768 {
      width: 66px;
      left: -40px;
    }
    @include min-1024 {
      width: 350px;
      left: -100%;
      height: 100%;
    }
    @include min-1200 {
      width: 470px;
    }
  }
  .stepper-nav {
    width: 100%;
    padding-top: 20px;
    position: relative;
    @include min-1024 {
      border-right: 1px solid var(--border-color);
      padding-right: 20px;
      padding-top: 100px;
      width: 310px;
    }
    @include min-1200 {
      width: 430px;
      padding-top: 160px;
    }
    .stepper-item {
      margin-bottom: 30px;
      @include min-1024 {
        margin-bottom: 30px;
      }
      @include min-1200 {
        margin-bottom: 92px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .stepper-item-details {
        align-items: flex-start;
        .stepper-icon {
          padding: 8px;
          background-color: var(--white-color);
          border-radius: 50%;
          @include min-1024 {
            padding: 14px;
          }
          .stepper-icon-inner {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            @include min-1024 {
              width: 48px;
              height: 48px;
            }
            svg {
              font-size: 20px;
              @include min-1024 {
                font-size: 32px;
              }
            }
            .dot-icon {
              width: 10px;
              height: 10px;
              background-color: var(--white-color);
              border-radius: 50%;
              @include min-1024 {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        .stepper-item-heading {
          padding-left: 14px;
          @include min-1024 {
            padding-left: 24px;
          }
          h3 {
            font-size: 16px;
            line-height: 22px;
            color: var(--dark-blue);
            font-weight: 400;
            margin: 5px 0 8px 0;
            @include min-1024 {
              margin: 15px 0 12px 0;
              font-size: 18px;
            }
            @include min-1200 {
              margin: 28px 0 12px 0;
            }
          }
          p {
            color: var(--light-grey);
            max-width: 308px;
            font-size: 14px;
            @include min-1024 {
              font-size: 16px;
              line-height: 25px;
            }
          }
        }
      }
      &.completed {
        .stepper-item-details {
          .stepper-icon {
            .stepper-icon-inner {
              background-color: var(--dark-blue);
              svg {
                color: var(--white-color);
              }
              .dot-icon {
                display: none;
              }
            }
          }
        }
      }
      &.current {
        .stepper-item-details {
          .stepper-icon {
            .stepper-icon-inner {
              background-color: var(--dark-blue);
              svg {
                display: none;
              }
            }
          }
        }
      }
      &.pending {
        .stepper-item-details {
          .stepper-icon {
            .stepper-icon-inner {
              background-color: transparent;
              border: 1px solid var(--dark-blue);
              svg {
                display: none;
              }
              .dot-icon {
                background-color: var(--dark-blue);
              }
            }
          }
        }
      }
    }
  }
  .stepper-nav-content {
    width: 100%;
    margin-top: 50px;
    @include min-1024 {
      width: calc(100% - 310px);
      padding-left: 40px;
      padding-top: 52px;
      margin-top: 0;
    }
    @include min-1200 {
      width: calc(100% - 430px);
      padding-left: 60px;
    }
    @include min-1440 {
      padding-left: 84px;
    }
    .stepper-nav-content-item {
      display: none;
      &.active {
        display: block;
      }
      .header-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 30px;
        @include min-1024 {
          font-size: 34px;
          line-height: 48px;
          margin-bottom: 45px;
        }
        @include min-1440 {
          font-size: 42px;
          line-height: 54px;
          margin-bottom: 56px;
        }
      }
      .btn-wrap {
        padding: 0 20px;
        margin-top: 20px;
        @include min-1200 {
          margin-top: 0;
          padding: 0 36px;
        }
        .btn {
          min-width: 275px;
          @include mobile {
            width: 100%;
            min-width: auto;
            margin-bottom: 10px;
          }
          @include min-1024 {
            min-width: 240px;
          }
          @include min-1200 {
            min-width: 275px;
          }
          &.btn-primary {
            background-color: var(--dark-blue);
          }
        }
        .btn-secondary {
          border: 1px solid #cfcfcf;
          color: var(--light-grey);
          svg {
            margin-right: 15px;
            position: relative;
            top: -1px;
          }
        }
      }
      .form-details {
        border: 1px solid #eeeeee;
        border-radius: 8px;
        padding-bottom: 20px;
        @include min-1024 {
          padding-bottom: 40px;
        }
        @include min-1200 {
          padding-bottom: 48px;
        }
        .title {
          padding: 14px 20px;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: var(--dark-blue);
          border-bottom: 1px solid #eeeeee;
          @include min-1024 {
            font-size: 24px;
          }
          @include min-1200 {
            padding: 24px 36px;
          }
        }
        .input-field-group {
          padding: 14px 20px 0 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          @include min-1200 {
            padding: 36px 36px 0 36px;
          }
          .input-field-item {
            width: 100%;
            margin-bottom: 24px;
            @include min-768 {
              width: calc(50% - 17px);
            }
            .input-box {
              border: 1px solid #c5c7d0;
              min-height: 48px;
            }
            label {
              line-height: 24px;
              margin-bottom: 12px;
              span {
                color: #ed3a3a;
                font-size: 17px;
              }
            }
            .without-background {
              display: flex;
              justify-content: space-between;
              .btn {
                color: var(--light-grey);
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                text-align: left;
                svg {
                  position: absolute;
                  right: 20px;
                }
              }
            }
            .light-grey.mt-3 {
              @include mobile {
                margin-top: 0 !important;
              }
            }
          }
        }
        .web-and-mobile-step-info {
          padding: 14px 20px 0 20px;
          @include min-1200 {
            padding: 36px 36px 0 36px;
          }
          .note {
            line-height: 24px;
            color: var(--light-grey);
            span {
              font-weight: 600;
              color: #ed3a3a;
            }
          }
          .input-field-group {
            padding: 0;
            display: unset;
            .add-url {
              margin: 30px 0;
              @include min-1200 {
                margin: 52px 0 42px 0;
              }
              .input-field-item {
                max-width: 580px;
                width: 100%;
                margin-bottom: 0;
                .input-box {
                  width: 50%;
                  margin-right: 24px;
                  @include mobile {
                    width: 100%;
                    margin-right: 0;
                  }
                }
                .btn-wrap {
                  padding: 0;
                  @include mobile {
                    margin-top: 10px !important;
                    width: 100%;
                  }
                  .btn {
                    background: #33b469;
                    padding: 15px 42px;
                    min-width: auto;
                    &:hover {
                      border-color: #33b469;
                    }
                  }
                }
              }
            }
            .added-url-list {
              margin-bottom: 40px;
              @include min-1024 {
                min-height: 315px;
                height: 315px;
                overflow: auto;
                padding-right: 20px;
              }
              p {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 24px;
              }
              .input-field-item {
                .input-box {
                  border-radius: 4px 0 0 4px;
                  color: var(--body-color);
                  &::placeholder {
                    color: var(--body-color);
                  }
                }
                .btn {
                  background-color: transparent;
                  border: 1px solid #c5c7d0;
                  border-left: 0;
                  border-radius: 0 4px 4px 0;
                  color: var(--body-color);
                  font-size: 16px;
                  padding: 10px 18px;
                  @include mobile {
                    font-size: 14px;
                    padding: 10px 14px;
                  }
                }
              }
            }
          }
        }
        .profile-info {
          padding-top: 0;
          margin-top: 14px;
          @include min-1024 {
            max-height: 525px;
            overflow: auto;
            margin-right: 20px;
          }
          @include min-1200 {
            padding-top: 36px;
          }
          .input-field-group {
            margin-bottom: 40px;
            display: block;
            .add-url {
              margin: 0 0 30px 0;
              @include min-1200 {
                margin: 0 0 42px 0;
              }
              .input-box {
                width: 50%;
                margin-right: 24px;
                @include mobile {
                  width: 100%;
                  margin-right: 0;
                  margin-top: 0;
                }
              }
            }
            .input-box {
              color: var(--body-color);
              width: 100%;
              margin-top: 24px;
              @include min-1024 {
                width: calc(50% - 10px);
                margin-top: 24px;
              }
            }
            .input-field-item {
              width: 100%;
              margin-bottom: 0;
              textarea {
                min-height: 100px;
                resize: none;
                width: 100%;
              }
            }
            &.with-column {
              @include min-1024 {
                margin-bottom: 40px;
              }
              .input-field-item {
                width: 100%;
                margin-bottom: 24px;
                @include min-1024 {
                  width: calc(50% - 10px);
                  margin-bottom: 0;
                }
                .input-box {
                  width: 100%;
                }
              }
            }
          }
          .input-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
          }
        }
        .checkbox-group {
          margin-bottom: 24px;
          margin-top: 0px;
          padding: 0 20px;
          @include min-1200 {
            margin-top: 14px;
            padding: 0 36px;
          }
        }
        &.get-started {
          .title {
            background-color: #33b469;
            color: white;
            border-radius: 8px 8px 0 0;
            padding: 36px;
          }
          .thank-you-msg {
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            color: #33b469;
          }
          .user-id {
            font-size: 20px;
            line-height: 24px;
            margin: 40px 0 34px;
            span {
              color: #33b469;
            }
          }
          .desc {
            p {
              line-height: 24px;
              color: var(--light-grey);
              margin-bottom: 12px;
            }
          }
          h5 {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            margin: 40px 0 32px 0;
          }
          .btn-wrap {
            .btn {
              min-width: 135px;
            }
          }
        }
      }
    }
    .list {
      margin: 40px 0;
      li {
        margin-left: 40px;
        @include mobile {
          margin-left: 0px;
          width: 100%;
          margin-bottom: 8px;
        }
        &:first-child {
          margin-left: 0;
        }
        a {
          color: var(--light-grey);
          line-height: 25px;
        }
      }
    }
  }
}
.not-found-page {
  text-align: center;
  .page-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    img {
      max-width: 250px;
      margin: 0 auto;
      @include mobile {
        max-width: 200px;
      }
    }
    h1 {
      font-size: 42px;
      line-height: 62px;
      margin: 30px 0 10px 0;
      color: var(--primary-color);
      @include mobile {
        font-size: 28px;
        line-height: 36px;
      }
    }
    p {
      color: var(--light-grey);
      font-size: 18px;
      @include mobile {
        font-size: 16px;
      }
    }
    .btn-wrap {
      margin-top: 50px;
    }
  }
}

/* Whislist Css start here */
.wishlist-section {
  .heading {
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 14px;
      @include mobile {
        font-size: 26px;
      }
      span {
        color: #666666;
        margin-left: 5px;
        font-weight: 400;
        font-size: 30px;
        @include mobile {
          font-size: 24px;
        }
      }
    }
    .btn-wrap {
      .btn {
        padding: 14px 31px;
        @include tablet {
          padding: 12px 20px;
          margin-top: 15px;
        }
        svg {
          font-size: 22px;
          margin-right: 12px;
          @include tablet {
            font-size: 18px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .trending-books-section {
    .trending-books-list {
      .add-to-cart-book-box {
        .btn-wrap {
          .btn {
            padding: 14px 20px;
          }
          .btn-add-to-cart {
            svg {
              font-size: 18px;
            }
          }
          .btn-grey {
            padding: 11px 19px;
            margin-right: 18px;
            svg {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

/* Cart Section CSS */
.cart-section {
  .heading {
    margin-bottom: 62px;
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 14px;
      @include mobile {
        font-size: 26px;
      }
      span {
        color: #666666;
        margin-left: 5px;
        font-weight: 400;
        font-size: 30px;
        @include mobile {
          font-size: 24px;
        }
      }
    }
  }
  .cart-wrapper {
    .left-part {
      width: 100%;
      @include min-1024 {
        width: 55%;
      }
      @include min-1440 {
        width: 65%;
      }
      ul {
        li {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          padding: 24px;
          margin-bottom: 24px;
          position: relative;
          &:last-child {
            margin-bottom: 0;
          }
          .book-detail {
            .heading-title {
              padding-left: 24px;
              h2 {
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 8px;
                padding-right: 40px;
              }
              .book-label {
                display: flex;
                margin-top: 20px;
                flex-wrap: wrap;
                @include min-1024 {
                  margin-top: 38px;
                }
                .label-item {
                  border: 1px solid #cfcfcf;
                  border-radius: 4px;
                  height: 34px;
                  display: flex;
                  align-items: center;
                  padding: 0 10px;
                  color: #666666;
                  margin-right: 16px;
                  margin-bottom: 5px;
                  @include min-1440 {
                    margin-bottom: 0px;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  svg {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
          .book-data {
            width: 100%;
            @include min-1920 {
              width: unset;
            }
            .btn-wrap {
              display: flex;
              justify-content: flex-end;
              .btn-grey {
                padding: 12px;
                position: absolute;
                top: 24px;
                right: 24px;
                padding: 8px;
                @include min-1920 {
                  position: relative;
                  top: 0;
                  right: 0;
                  padding: 12px;
                }
                svg {
                  font-size: 16px;
                  @include min-1920 {
                    font-size: 22px;
                  }
                }
              }
            }
            .price {
              margin-top: 24px;
              min-width: 290px;
              @include min-1920 {
                margin-top: 38px;
              }
              .price-number {
                font-size: 24px;
                line-height: 34px;
                span {
                  font-size: 18px;
                  color: #666666;
                  text-decoration: line-through;
                  margin-left: 12px;
                }
              }
              .rating {
                font-size: 18px;
                display: flex;
                align-items: center;
                svg {
                  font-size: 20px;
                  color: #fdc12a;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
    .right-part {
      width: 100%;
      margin-top: 30px;
      @include min-1024 {
        width: 45%;
        padding-left: 35px;
        margin-top: 0;
      }
      @include min-1440 {
        width: 35%;
      }
      .cart-summary {
        border: 2px solid #e3e3e3;
        box-shadow: 0px 4px 20px rgba(128, 128, 128, 0.2);
        border-radius: 8px;
        @include min-1024 {
          position: sticky;
          top: 30px;
        }
        .summary-heading {
          padding: 18px 20px;
          margin-bottom: 50px;
          border-bottom: 1px solid #cfcfcf;
          @include mobile {
            margin-bottom: 30px;
          }
          h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
          }
        }
        .cart-details {
          padding: 0 20px 20px 20px;
          .book-label {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            border-bottom: 1px solid #cfcfcf;
            padding-bottom: 24px;
            margin-bottom: 24px;
            .label-item {
              border: 1px solid #cfcfcf;
              border-radius: 4px;
              height: 34px;
              display: flex;
              align-items: center;
              padding: 0 10px;
              color: #666666;
              margin-right: 16px;
              margin-bottom: 5px;
              @include min-1600 {
                margin-bottom: 0px;
              }
              &:last-child {
                margin-right: 0;
              }
              svg {
                margin-right: 8px;
              }
            }
          }
          .input-field {
            label {
              font-size: 18px;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 10px;
              span {
                color: #ed3a3a;
                font-size: 17px;
              }
            }
          }
          .error-message {
            margin: 13px 0 25px 0;
            svg {
              font-size: 26px;
              min-width: 26px;
            }
            p {
              margin-left: 16px;
              line-height: 24px;
            }
          }
          .save-more {
            border-bottom: 1px solid #cfcfcf;
            padding-bottom: 24px;
            margin-bottom: 34px;
            .save-more-button {
              background-color: #f7f7f7;
              color: var(--dark-blue);
              border: 1px solid #cfcfcf;
              border-radius: 5px;
              display: flex;
              padding: 8px 16px;
              p {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                padding-right: 10px;
                svg {
                  font-size: 16px;
                  margin-right: 10px;
                }
                span {
                  font-weight: 400;
                }
              }
              a {
                color: var(--dark-blue);
                svg {
                  font-size: 12px;
                  position: relative;
                  top: 2px;
                }
              }
            }
          }
          .grand-total {
            border-top: 1px solid #cfcfcf;
            padding-top: 24px;
            margin-top: 34px;
            .total {
              font-weight: 500;
              font-size: 24px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

/* contact-us-section CSS */
.contact-us-section {
  background-color: #4e1e82;
  color: white;
  position: relative;
  padding: 30px 0;
  @include min-992 {
    padding: 40px 0;
  }
  @include min-1200 {
    padding: 60px 0;
  }
  @include min-1600 {
    padding: 72px 0;
  }
  .contact-us-content {
    .left-part {
      width: 100%;
      text-align: center;
      @include min-1200 {
        text-align: left;
      }
      @include min-1200 {
        width: 50%;
      }
      h1 {
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 32px;
        @include min-768 {
          font-size: 36px;
          line-height: 46px;
        }
        @include min-1024 {
          font-size: 42px;
          line-height: 52px;
        }
        @include min-1200 {
          font-size: 40px;
          line-height: 54px;
        }
        @include min-1440 {
          font-size: 47px;
          line-height: 64px;
        }
        @include min-1600 {
          font-size: 52px;
          line-height: 72px;
        }
        @include min-1920 {
          font-size: 58px;
          line-height: 87px;
        }
      }
      p {
        color: rgba(255, 255, 255, 0.7);
        line-height: 36px;
        font-size: 18px;
      }
      .image {
        margin-top: 30px;
        margin-bottom: 30px;
        @include min-992 {
          margin-top: 60px;
        }
        @include min-1200 {
          margin-top: 90px;
          margin-bottom: 0;
        }
        .user {
          position: relative;
          z-index: 2;
          margin: 0 auto;
          @include min-1200 {
            margin: 0;
          }
        }
        .user-background {
          position: absolute;
          bottom: 0;
          left: 0;
          display: none;
          @include min-1200 {
            display: block;
          }
        }
      }
    }
    .right-part {
      width: 100%;
      z-index: 1;
      @include min-1200 {
        width: 45%;
      }
      @include min-1440 {
        width: 41%;
      }
      .contact-form {
        background-color: white;
        color: var(--body-color);
        border: 2px solid #8131d9;
        border-radius: 12px;
        padding: 30px 5px;
        @include min-768 {
          padding: 30px 15px;
        }
        @include min-992 {
          padding: 40px 15px;
        }
        @include min-1600 {
          padding: 72px 15px;
        }
        .input-field-item {
          margin-bottom: 34px;
          @include min-1200 {
            margin-bottom: 24px;
          }
          @include min-1600 {
            margin-bottom: 34px;
          }
          .without-background {
            display: flex;
            justify-content: space-between;
            .btn {
              color: var(--light-grey);
              font-size: 16px;
              font-weight: 400;
              width: 100%;
              text-align: left;
              svg {
                position: absolute;
                right: 20px;
              }
            }
          }
          textarea {
            min-height: 100px;
          }
        }
        .btn-wrap {
          width: 100%;
          margin-bottom: 16px;
          .btn {
            width: 100%;
          }
        }
        .desc {
          font-size: 14px;
          color: #666666;
          a {
            color: #2f80ed;
            text-decoration: underline;
          }
        }
      }
    }
  }
}


/* about-us-page-section CSS */
.about-us-page-section {
  .video-container {
    background: url("../images/vide-background.jpg") no-repeat;
    background-size: cover;
    padding: 68px 0;
    position: relative;
    .video {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 24px;
        pointer-events: none;
      }
      video {
        width: 100%;
        max-height: 640px;
        object-fit: cover;
        border: 2px solid #ffffff;
        border-radius: 24px;
      }
    }
  }
}
